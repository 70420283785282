<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Sales by ({{month_of}})</span>

    </v-card-title>

    <v-card-text>
      <v-list class="pb-0">
        <v-list-item
          v-for="(data,index) in listOfSales"
          :key="data.country"
          :class="`d-flex align-center px-0 ${index > 0 ? 'mt-0':''}`"
        >
          <v-avatar
            color="primary"
            size="40"
            :class="`white--text font-weight-medium me-4`"
          >
            <span class="text-base">{{ data.abbr }}</span>
          </v-avatar>

          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="me-2">
              <div class="font-weight-semibold">
                <span class="text--primary text-base me-1">{{ data.amount }}</span>

                <v-icon
                  v-if="data.count != ' '"
                  size="20"
                  :color="data.count > 0 ? 'success':'error'"
                >
                  {{ data.count > 0 ? icons.mdiChevronUp: icons.mdiChevronDown }}
                </v-icon>

                <span v-if="data.count != ' '"
                      :class="`text-xs ${data.count > 0 ? 'success--text':'error--text'}`">{{data.count}} Sales</span>
              </div>

              <v-list-item-subtitle class="text-xs">
                {{ data.category }}
              </v-list-item-subtitle>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mdiDotsVertical, mdiChevronUp, mdiChevronDown} from '@mdi/js'

  export default {
    props: {
      month_of: String,
      listOfSales: Array,
    },
    setup() {
      return {
        icons: {
          mdiDotsVertical,
          mdiChevronUp,
          mdiChevronDown,
        },
      }
    },
  }
</script>
