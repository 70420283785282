<template>
  <v-card>
    <div class="d-flex flex-sm-row flex-column">
      <div class="flex-grow-1">
        <v-card-title>
          <span class="me-3">Deposits</span>
          <v-spacer></v-spacer>
          <v-btn
            v-if="today_time==='17'"
            icon
            small
            class="me-n3 mt-n2"
            @click="print_deposits"
          >
            <v-icon>
              {{ icons.mdiPrinter }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(data,index) in deposit"
              :key="data.id"
              :class="`d-flex px-0 ${index > 0 ? 'mt-1':''}`"
            >
              <v-img
                contain
                max-height="30"
                max-width="30"
                :src="data.img"
                class="me-3"
              ></v-img>

              <div class="d-flex align-center flex-grow-1 flex-wrap">
                <div class="me-auto pe-2">
                  <h4 class="font-weight-semibold">
                    {{ data.account_name }}
                  </h4>
                  <span class="text-xs">{{ data.account_no }}</span>
                </div>

                <span class="font-weight-semibold success--text">{{ data.amount }}</span>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>

      <v-divider
        class="my-sm-5 mx-5"
        :vertical="$vuetify.breakpoint.smAndUp"
      ></v-divider>

      <div class="flex-grow-1">
        <v-card-title>
          <span class="me-3">Withdrawals</span>
          <v-spacer></v-spacer>
          <v-btn
            v-if="today_time==='17'"
            icon
            small
            class="me-n3 mt-n2"
            @click="print_withdrawals"
          >
            <v-icon>
              {{ icons.mdiPrinter }}
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(data,index) in withdraw"
              :key="data.id"
              :class="`d-flex px-0 ${index > 0 ? 'mt-1':''}`"
            >
              <v-img
                max-height="30"
                max-width="30"
                :src="data.img"
                class="me-3"
              ></v-img>

              <div class="d-flex align-center flex-grow-1 flex-wrap">
                <div class="me-auto pe-2">
                  <h4 class="font-weight-semibold">
                    {{ data.account_name }}
                  </h4>
                  <span class="text-xs">{{ data.account_no }}</span>
                </div>

                <span class="font-weight-semibold error--text">{{ data.amount }}</span>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>

<script>
  import {mdiPrinter} from '@mdi/js'
  import moment from "moment";
  import {mapGetters} from "vuex";

  export default {
    props: {
      deposit: Array,
      withdraw: Array,
      total_deposits: String,
      total_withdrews: String,
    },
    setup() {
      return {
        interval: {},
        today_time: 0,
        icons: {
          mdiPrinter,
        },
      }
    },
    mounted() {
      this.interval = setInterval(this.todays_time, 1000);
    },
    beforeDestroy() {
      clearInterval(this.interval)
    },
    computed: {
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_address', 'branch_contact_no']),
    },
    methods: {
      todays_time() {
        this.today_time = moment().format('k')
      },
      print_deposits() {
        var imgData = this.company_logo
        var array = []
        var total_dep = this.total_deposits

        array.push(
          [
            {text: 'Account #', alignment: 'center', style: 'label'},
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Deposits', alignment: 'center', style: 'label'},
          ]
        )
        if (this.deposit.length > 0) {
          this.deposit.forEach(function (item, index, payment) {
            array.push(
              [
                {text: item.account_no, alignment: 'center'},
                {text: item.account_name, alignment: 'left'},
                {text: item.amount, alignment: 'left'},
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              array.push(
                [
                  {
                    text: '',
                    border: [false, false, false, false]
                  },
                  {
                    color: 'red',
                    text: 'TOTAL: ',
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {
                    color: 'red',
                    text: total_dep,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                ]
              )
            }
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'DEPOSITS TRANSACTION', style: 'title'},
            {text: 'TELLER TRANSACTIONS AS OF ' + moment().format('MMMM D, YYYY'), style: 'title'},
            '================================================================================',
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [100, 200, 100],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
      print_withdrawals() {
        var imgData = this.company_logo
        var array = []
        var total_dep = this.total_withdrews

        array.push(
          [
            {text: 'Account #', alignment: 'center', style: 'label'},
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Withdrew', alignment: 'center', style: 'label'},
          ]
        )
        if (this.withdraw.length > 0) {
          this.withdraw.forEach(function (item, index, payment) {
            array.push(
              [
                {text: item.account_no, alignment: 'center'},
                {text: item.account_name, alignment: 'left'},
                {text: item.amount, alignment: 'left'},
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              array.push(
                [
                  {
                    text: '',
                    border: [false, false, false, false]
                  },
                  {
                    color: 'red',
                    text: 'TOTAL: ',
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {
                    color: 'red',
                    text: total_dep,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                ]
              )
            }
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'WITHDRAWALS TRANSACTION', style: 'title'},
            {text: 'TELLER TRANSACTIONS AS OF ' + moment().format('MMMM D, YYYY'), style: 'title'},
            '================================================================================',
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [100, 200, 100],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    },
  }
</script>
