<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Earnings in ({{month_of}})</span>
    </v-card-title>

    <v-card-text>
      <div class="d-flex align-center">
        <h1 class="text-4xl font-weight-semibold">
          P{{totalEarnings}}
        </h1>
      </div>
    </v-card-text>

    <v-card-text>
      <div
        v-for="(earning,index) in totalEarning"
        :key="earning.category"
        :class="`d-flex align-start ${index >0 ? 'mt-5':''}`"
      >
        <div class="d-flex align-center flex-grow-1 flex-wrap">
          <div>
            <h4 class="font-weight-medium">
              {{ earning.category }}
            </h4>
          </div>

          <v-spacer></v-spacer>

          <div class="ms-1">
            <p class="text--primary font-weight-medium mb-1">
              {{ earning.amount }}
            </p>
            <v-progress-linear
              value="85"
              color='info'
            ></v-progress-linear>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mdiDotsVertical, mdiMenuUp} from '@mdi/js'

  export default {
    props: {
      month_of: String,
      totalEarnings: String,
      totalEarning: Array,
    },
    setup() {
      return {
        icons: {mdiDotsVertical, mdiMenuUp},
      }

    },
  }
</script>
