<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Statistics Card</span>
      <v-spacer></v-spacer>
      <!--      <v-btn-->
      <!--        icon-->
      <!--        small-->
      <!--        class="me-n3 mt-n2"-->
      <!--      >-->
      <!--        <v-icon>-->
      <!--          {{ icons.mdiDotsVertical }}-->
      <!--        </v-icon>-->
      <!--      </v-btn>-->
    </v-card-title>

    <v-card-subtitle class="mb-8 mt-n5">
      <span class="font-weight-semibold text--primary me-1">Total {{growth}} Growth</span>
      <span>😎 this {{month_of}}</span>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.title).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import {
    mdiAccountOutline,
    mdiCurrencyUsd,
    mdiTrendingUp,
    mdiDotsVertical,
    mdiLabelOutline
  } from '@mdi/js'
  import {mapActions, mapGetters} from "vuex";

  export default {
    setup() {

      const resolveStatisticsIconVariation = data => {
        if (data === 'Sales') return {icon: mdiTrendingUp, color: 'primary'}
        if (data === 'Members') return {icon: mdiAccountOutline, color: 'success'}
        if (data === 'Loans') return {icon: mdiCurrencyUsd, color: 'info'}

        return {icon: mdiAccountOutline, color: 'success'}
      }

      return {
        resolveStatisticsIconVariation,

        // icons
        icons: {
          mdiDotsVertical,
          mdiTrendingUp,
          mdiAccountOutline,
          mdiLabelOutline,
          mdiCurrencyUsd,
        },
      }
    },
    data() {
      return {
        statisticsData: [],
        growth: '0 %',
      }
    },
    created() {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const data = new FormData()
        data.append('branch_id', this.branch_id);
        data.append('month_of', this.month_of);
        this.statistics_card(data)
          .then((response) => {
            this.statisticsData = response.data[0].statistics
            this.growth = response.data[0].growth
          })
          .catch(error => {
            console.log(error)
          })
      }, 100)
    },
    computed: {
      ...mapGetters('authentication', ['month_of', 'branch_id']),
    },
    methods: {
      ...mapActions('dashboard', ['statistics_card']),
    }
  }
</script>
