<template>
  <div>
    <v-row
      v-if="position==='TELLER'"
    >
      <v-col
        cols="12"
        md="12">
        <teller-dashboard-funds-card
          :fund_amount="fund_amount"
          :cash_on_hand="cash_on_hand"
          :total_deposits="total_deposits"
          :total_withdrews="total_withdrews"
        ></teller-dashboard-funds-card>
      </v-col>
      <v-col
        cols="12"
        md="12">
        <teller-dashboard-card-deposit-and-withdraw
          :deposit="deposit"
          :withdraw="withdraw"
        ></teller-dashboard-card-deposit-and-withdraw>
      </v-col>
    </v-row>
    <v-row
      v-if="position!='TELLER'"
    >
      <v-col
        cols="12"
        md="4"
      >
        <dashboard-anouncement></dashboard-anouncement>
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <dashboard-statistics-card></dashboard-statistics-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="6"
      >
        <dashboard-card-sales-by-countries
          :month_of="month_of"
          :listOfSales="listOfSales"
        ></dashboard-card-sales-by-countries>
      </v-col>

      <v-col
        cols="12"
        md="6"
        sm="6"
      >
        <dashboard-card-total-earning
          :totalEarning="totalEarning"
          :totalEarnings="totalEarnings"
          :month_of="month_of"
        ></dashboard-card-total-earning>
      </v-col>

      <v-col
        cols="12"
        md="12"
      >
        <teller-dashboard-card-deposit-and-withdraw-view
          :deposit="deposit"
          :withdraw="withdraw"
          :deposit_share="deposit_share"
          :withdraw_share="withdraw_share"
          :deposit_time="deposit_time"
          :withdraw_time="withdraw_time"
          :deposit_passbook="deposit_passbook"
          :withdraw_passbook="withdraw_passbook"
        ></teller-dashboard-card-deposit-and-withdraw-view>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import {mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline} from '@mdi/js'
  import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'

  // demos
  import DashboardAnouncement from './DashboardAnouncement.vue'
  import DashboardStatisticsCard from './DashboardStatisticsCard.vue'
  import DashboardCardTotalEarning from './DashboardCardTotalEarning.vue'
  import DashboardCardSalesByCountries from './DashboardCardSales.vue'

  import TellerDashboardCardDepositAndWithdraw
    from './teller_interface/TellerDashboardCardDepositAndWithdraw'
  import TellerDashboardCardDepositAndWithdrawView
    from '../reports_interface/teller_transaction_components/TellerCardDepositAndWithdraw'
  import TellerDashboardFundsCard from './teller_interface/TellerDashboardFundsCard'
  import {mapActions, mapGetters} from "vuex";

  export default {
    components: {
      TellerDashboardFundsCard,
      TellerDashboardCardDepositAndWithdraw,
      TellerDashboardCardDepositAndWithdrawView,

      StatisticsCardVertical,
      DashboardAnouncement,
      DashboardStatisticsCard,
      DashboardCardTotalEarning,
      DashboardCardSalesByCountries,
    },
    computed: {
      ...mapGetters('authentication', ['branch_id', 'position', 'user_id', 'month_of']),
    },
    data() {
      return {
        deposit: [],
        withdraw: [],
        deposit_share: [],
        withdraw_share: [],
        deposit_time: [],
        withdraw_time: [],
        deposit_passbook: [],
        withdraw_passbook: [],
        totalEarnings: '0',
        totalEarning: [],
        listOfSales: [],
        total_deposits: '0',
        total_withdrews: '0',
        fund_amount: '0',
        cash_on_hand: '0',
      }
    },
    methods: {
      ...mapActions('teller_fund', ['teller_daily_transaction_data', 'teller_monthly_transaction_data']),
      ...mapActions('income_summary', ['get_summary_income']),
      ...mapActions('summary_report', ['get_summary_report']),
    },
    mounted() {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        if (this.position === 'TELLER') {
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('teller_id', this.user_id);
          this.teller_daily_transaction_data(data)
            .then(response => {
              this.deposit = response.data[0].deposit
              this.withdraw = response.data[0].withdraw
              this.total_deposits = response.data[0].total_deposits
              this.total_withdrews = response.data[0].total_withdrews
              this.fund_amount = response.data[0].fund
              this.cash_on_hand = response.data[0].cash_on_hand
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          const data = new FormData()
          data.append('branch_id', this.branch_id);
          data.append('month_of', this.month_of);

          this.get_summary_report(data)
            .then((response) => {
              this.listOfSales = response.data
            })
            .catch(error => {
              console.log(error)
            })

          this.get_summary_income(data)
            .then((response) => {
              this.totalEarnings = response.data[0].total_income
              this.totalEarning = response.data[0].data
            })
            .catch(error => {
              console.log(error)
            })

          this.teller_monthly_transaction_data(data)
            .then(response => {
              this.deposit = response.data[0].deposit
              this.withdraw = response.data[0].withdraw
              this.deposit_share = response.data[0].deposit_share
              this.withdraw_share = response.data[0].withdraw_share
              this.deposit_time = response.data[0].deposit_time
              this.withdraw_time = response.data[0].withdraw_time
              this.deposit_passbook = response.data[0].deposit_passbook
              this.withdraw_passbook = response.data[0].withdraw_passbook
              this.data_table_loading = false
            })
            .catch(error => {
              this.data_table_loading = false
            })
        }

      }, 200)

    },
    setup() {
      const totalProfit = {
        statTitle: 'Total Profit',
        icon: mdiPoll,
        color: 'success',
        subtitle: 'Weekly Project',
        statistics: '$25.6k',
        change: '+42%',
      }

      const totalSales = {
        statTitle: 'Refunds',
        icon: mdiCurrencyUsd,
        color: 'secondary',
        subtitle: 'Past Month',
        statistics: '$78',
        change: '-15%',
      }

      // vertical card options
      const newProject = {
        statTitle: 'New Project',
        icon: mdiLabelVariantOutline,
        color: 'primary',
        subtitle: 'Yearly Project',
        statistics: '862',
        change: '-18%',
      }

      const salesQueries = {
        statTitle: 'Sales Quries',
        icon: mdiHelpCircleOutline,
        color: 'warning',
        subtitle: 'Last week',
        statistics: '15',
        change: '-18%',
      }

      return {
        totalProfit,
        totalSales,
        newProject,
        salesQueries,
      }
    },
  }
</script>
