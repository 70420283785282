<template>
  <v-card class="greeting-card">
    <v-row class="ma-0 pa-0">
      <v-col cols="12">
        <v-card-title class="text-no-wrap pt-1 ps-2">
          Announcement
        </v-card-title>
        <v-card-text class="d-flex align-center pb-2 ps-2">
          <div>
            <p class="text font-weight-semibold mb-2">
              {{announcement_message}}
            </p>
          </div>
          <v-btn
            block
            color="primary"
            class="mt-6"
            outlined
          >
            Details
          </v-btn>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
  export default {
    data() {
      return {
        announcement_message: '',
      }
    },
  }
</script>

<style lang="scss" scoped>
  .greeting-card {
    position: relative;

    .greeting-card-bg {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .greeting-card-trophy {
      position: absolute;
      bottom: 10%;
      right: 8%;
    }
  }

  // rtl
  .v-application {
    &.v-application--is-rtl {
      .greeting-card-bg {
        right: initial;
        left: 0;
        transform: rotateY(180deg);
      }

      .greeting-card-trophy {
        left: 8%;
        right: initial;
      }
    }
  }
</style>
